<template>
  <cks-page-wrap v-loading="loading" style="padding-bottom: 100px;">
    <cks-edit-form
      @updatePosi="updatePosi"
      :isPosi="true"
      title="新闻中心编辑"
      @save="save"
      ref="formRef"
      label-position="top"
      :rules="rules"
      :model="form"
    >
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item label="新闻标题 (建议标题长度20字以内)" prop="title">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="新闻类型" prop="newsType">
            <cks-select class="cks-size--fw" v-model="form.newsType" :options="newsTypeList"></cks-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="标题图片(建议图片大小210px * 190px, 不应超过1M大小)" prop="titlePic">
            <cks-upload v-model="form.titlePic" @updateUrl="updateUrl" :fileUrl="form.titlePic" :limit="1"></cks-upload>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="id">
          <el-form-item label="创建日期" prop="createdTime">
            <el-input disabled v-model="form.createdTime"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="id">
          <el-form-item label="更新日期" prop="updatedTime">
            <el-input disabled v-model="form.updatedTime"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="新闻简介" prop="content">
            <el-input type="textarea" v-model="form.content"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="detail">
            <template #label>
              <span style="color: red;">*</span> 新闻内容
            </template>
            <div v-if="temp" class="posi"></div>
            <quill-editor ref="quillEditorRef" v-model="form.detail" :options="editorOption"></quill-editor>
          </el-form-item>
        </el-col>
      </el-row>
    </cks-edit-form>
  </cks-page-wrap>
</template>

<script>
import {
  toRefs, reactive, onMounted,
} from 'vue';
import {
  showSuccess, getRequireRule, showError,
} from '@/utils';
import apis from '@/request/apis';
import { useRouter } from 'vue-router';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  components: {
    QuillEditor,
  },
  props: ['id'],
  setup(props) {
    const router = useRouter();
    const newsTypeList = [
      {
        label: '0',
        name: '企业动态',
      },
      {
        label: '1',
        name: '行业资讯',
      },
    ];
    const data = reactive({
      dialogImageUrl: '',
      dialogVisible: false,
      formRef: null,
      form: {
        newsType: '1',
        fileList: [],
      },
      rules: {
        title: [getRequireRule('新闻标题')],
        newsType: [getRequireRule('新闻类型')],
        titlePic: [getRequireRule('标题图片')],
        content: [getRequireRule('新闻简介')],
      },
      loading: false,
      temp: false,
      quillEditorRef: null,
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [
              { list: 'ordered' },
              { list: 'bullet' },
            ],
            [
              { color: [] },
              { background: [] },
            ],
            ['image'],
          ],
        },
      },
    });

    async function save(done) {
      try { await data.formRef.validate(); } catch (e) { return done(false); }
      if (data.quillEditorRef.getHTML() === '<p><br></p>') {
        done(false);
        showError(new Error('新闻内容不能为空!'));
        return;
      }
      try {
        await apis.newsCenter.save({
          ...data.form,
          detail: data.quillEditorRef.getHTML(),
        });
        showSuccess('保存成功');
        done();
        router.push({
          name: 'newsCenter',
        });
      } catch (e) {
        done(e);
      }
    }

    async function loadDetail() {
      data.loading = true;
      try {
        const res = await apis.newsCenter.getDetail({ id: props.id });
        res.newsType = String(res.newsType);
        Object.assign(data.form, res);
        if (data.form.detail) {
          data.quillEditorRef.setHTML(data.form.detail);
        }
        if (data.form.titlePic) {
          data.form.fileList = [{
            url: data.form.titlePic,
            name: data.form.titlePic,
          }];
        }
      } finally {
        data.loading = false;
      }
    }

    function handleRemove(val) {
      data.form.titlePic = '';
      data.form.fileList = data.form.fileList.filter(item => item.url !== val.url);
    }
    function handlePictureCardPreview(val) {
      data.dialogImageUrl = val.url;
      data.dialogVisible = true;
    }
    function handleSuccess(val, file) {
      const { name, url } = file;
      data.form.fileList.push({ name, url });
    }
    onMounted(() => {
      if (props.id) {
        loadDetail();
      } else {
        props.id || data.formRef?.unlock();
      }
    });

    const updatePosi = (val = false) => {
      data.temp = val;
    };

    const uploadFile = async (val) => {
      const { file } = val;
      try {
        const fd = new FormData();
        fd.append('file', file);
        const res = await apis.upload.uploadFile(fd);
        data.form.fileList = [{
          url: res.filePath,
          name: res.fileName,
        }];
        data.form.titlePic = res.filePath;
      } catch (err) {
        data.form.fileList = [];
      }
    };

    const updateUrl = (val) => {
      data.form.titlePic = val;
    };
    return {
      updateUrl,
      handleRemove,
      handleSuccess,
      handlePictureCardPreview,
      save,
      ...toRefs(data),
      updatePosi,
      newsTypeList,
      uploadFile,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .quill-editor {
  height: 400px;
}
::v-deep .ql-container{
  height: 300px;
}
.posi {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 20px;
  z-index: 999;
  background-color: #f5f7fa;
  opacity: 0.5;
  cursor:not-allowed;
}
.el-input {
  --el-input-focus-border: #2F51FF;
}
::v-deep.active-img-list .el-upload {
  display: none;
}
::v-deep .el-textarea__inner:focus {
  border: 1px solid #2F51FF;;
}
</style>
